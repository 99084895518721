<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('contractsAdd')"
          @click="$router.push('contracts/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('contractsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه العقود
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        field="code"
        header="رقم العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث برقم العقد"
          />
        </template>
      </Column>
      <Column
        field="clientsId.code"
        header="كود العميل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['clientsId.code']"
            class="p-column-filter"
            placeholder="بحث بكود العميل"
          />
        </template>
      </Column>
      <Column
        field="clientsId.name"
        filterMatchMode="contains"
        header="اسم العميل"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['clientsId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم العميل"
          />
        </template>
      </Column>
      <Column
        field="branchesId.name"
        header="اسم الفرع"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['branchesId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم الفرع"
          />
        </template>
      </Column>

      <Column
        field="date"
        header="تاريخ العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ العقد"
          />
        </template>
      </Column>
      <Column
        field="total"
        header="قيمه العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['total']"
            class="p-column-filter"
            placeholder="بحث قيمه العقد"
          />
        </template>
      </Column>

      <Column
        field="status"
        header="الحاله"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #body="slotProps">
          {{
            slotProps.data.installationsLogs.length > 0
              ? $getStatus(slotProps.data.installationsLogs[0].status)
              : slotProps.data.status
          }}

        </template>
        <template #filter>
          <InputText
            v-model="filters['status']"
            class="p-column-filter"
            placeholder="بحث بالحاله"
          />
        </template>
      </Column>

      <Column
        field="usersId.name"
        header="المندوب"
        v-if="$checkRoles('contractsShowAllData')"
      >
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث بالمندوب"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of usersList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="toInstalltionsAction(slotProps.data.id)"
            icon="pi pi-reply"
            v-tooltip="'تحويل للتركيبات'"
            v-if="
              $checkRoles('contractsWithoutPayment') &&
                slotProps.data.toInstalltions == 'new'
            "
            class="p-button-info p-ml-2  p-button-rounded"
            :disabled="disabledAdd"
          ></Button>
          <Button
            type="button"
            @click="$router.push('contracts/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            v-if="$checkRoles('contractsEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-tooltip="'حذف'"
            v-if="$checkRoles('contractsDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="materialsNote" header="تفاصيل المواد الخام">
              <template #body="slotProps">
                <p v-html="slotProps.data.materialsNote"></p>
              </template>
            </Column>
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="slotProps.data.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
          </DataTable>
          <DataTable
            :value="slotProps.data.payments"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه الدفعات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="id" header="الكود" />
            <Column field="name" header="الاسم" />
            <Column field="price" header="السعر" />

            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <span v-if="!slotProps2.data.paymentType">
                  لم يتم التحصيل بعد
                </span>
                <span v-else>
                  تم تحصيل الدفعه
                </span>
              </template>
            </Column>

            <!-- <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <Button
                  type="button"
                  icon="pi pi-dollar"
                  v-if="
                    $checkRoles('paymentTypeAdd') &&
                      !slotProps2.data.paymentType
                  "
                  @click="openBasic(slotProps2.data.id)"
                  class="p-button-info  p-button-rounded"
                ></Button>
                <span v-else>
                  تم الاستلام بوسطه {{ slotProps2.data.usersId.name }}
                </span>
              </template>
            </Column> -->
          </DataTable>

          <DataTable
            :value="slotProps.data.filesList,

            "
            class="p-datatable-customers table table-striped"
          >
            <template #header>
              قائمه الملفات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="name" header="النوع" />
            <Column field="file" header="الملف">
              <template #body="slotProps">
                <a :href="$baseUploadURL + slotProps.data.file">
                  {{ slotProps.data.file }}
                </a>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="الدفع"
      :visible.sync="displayBasic"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card row">
          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="date" class="form-label">
                تاريخ المعامله
              </label>
              <input
                class="form-control"
                type="date"
                id="date"
                disabled
                v-model="payment.date"
              />
            </div>
          </div>
          <div class="col-md-4 text-right">
            <div class="mb-3 ">
              <label for="paymentType" class="form-label">
                نوع المعامله
              </label>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cach"
                    name="paymentType"
                    value="cach"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="cach">كاش</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="check"
                    type="radio"
                    name="paymentType"
                    value="check"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="check">شيك</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="transfer"
                    name="paymentType"
                    value="transfer"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="transfer">تحويل</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="bondNumber" class="form-label">
                رقم السند
              </label>
              <input
                class="form-control"
                type="number"
                id="bondNumber"
                v-model="payment.bondNumber"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkNumber" class="form-label">
                رقم الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                type="number"
                id="checkNumber"
                v-model="payment.checkNumber"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkName" class="form-label">
                اسم الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                id="checkName"
                v-model="payment.checkName"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkDate" class="form-label">
                تاريخ الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                type="date"
                id="checkDate"
                v-model="payment.checkDate"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3 ">
              <label for="note" class="form-label">
                ملاحظات
              </label>
              <textarea
                class="form-control"
                type="note"
                id="note"
                v-model="payment.note"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3 ">
              <label for="files" class="form-label">
                مرفقات
              </label>
              <input
                class="form-control"
                type="file"
                id="files"
                multiple
                @change="previewImage"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="حفظ" icon="pi pi-check" @click="closeBasic" autofocus />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasic = false"
          class="p-button-text"
        />
      </template>
    </Dialog>

    <Dialog
      header="التحويل الى التركيبات"
      :visible.sync="displayBasicToInstalltions"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="note" class="form-label">
              ملاحظات
            </label>
            <textarea
              class="form-control"
              type="note"
              id="note"
              v-model="installations.note"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="حفظ"
          icon="pi pi-check"
          @click="toAddInstalltions"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToInstalltions = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      displayBasicToInstalltions: false,
      expandedRows: [],
      loading: true,
      payment: {
        // "2021-11-24"
        date: `${ye}-${mo}-${da}`,
        paymentType: 'cach',
        bondNumber: null,
        checkNumber: null,
        checkName: null,
        checkDate: null,
        note: null,
        files: [],
      },
      installations: {
        note: null,
        contractsId: null,
        date: null,
        usersId: null,
      },
      paymentId: null,
      filters: {},
      branchesList: [],
      clientsList: [],
      usersList: [],
      disabledAdd: false,
    };
  },
  methods: {
    toInstalltionsAction(id) {
      this.disabledAdd = true;

      this.$http
        .put(`contracts/toInstalltions/${id}`, {
          toInstalltions: 'in',
          status: 'فى التشغيل',
        })
        .then(
          () => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التحويل بنجاح',
              life: 3000,
            });
            this.getData();
          },

          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    async previewImage(ev) {
      if (ev.target.files.length > 0) {
        const list = [];
        for (const item of ev.target.files) {
          const itm = await this.$toBase64(item);
          await list.push(itm);
        }
        this.payment.files = await list;
      } else {
        this.payment.files = [];
      }
      return await true;
    },
    getData() {
      if (this.$checkRoles('contractsShowAllData')) {
        this.$http.get(`contracts/getContracts`).then(
          (response) => {
            this.loading = false;
            this.list = [];
            for (let item of response.data) {
              const filesList = [];
              let i = 1;
              if (item.files) {
                for (const itm of item.files) {
                  filesList.push({
                    name: ' ملف عقد' + i,
                    file: itm,
                  });
                  i = i + 1;
                }
              }
              if (item.payments) {
                for (const itm of item.payments) {
                  if (itm.files) {
                    let x = 1;
                    for (const it of itm.files) {
                      filesList.push({
                        name: ' ملف تحصيل دفعه' + x,
                        file: it,
                      });
                      x = x + 1;
                    }
                  }
                }
              }
              item.filesList = filesList;
              this.list.push(item);
            }
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        const user = JSON.parse(localStorage.admin);
        this.$http.get(`contracts/getContractsByUsersId/${user.id}`).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      }
    },
    openBasic(id) {
      this.displayBasic = true;
      this.paymentId = id;
    },
    closeBasic() {
      this.displayBasic = false;
      this.$http.put(`payments/${this.paymentId}`, this.payment).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`contracts/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`contracts/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('contractsVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
